<template>
  <div class="not-printable floating-card">
    <v-card v-if="open">
      <v-card-text>
        <v-container fluid class="pa-0">
          <v-row dense>
            <v-col cols="6">
              <div :class="{'font-weight-bold': !court}">1. Select a court</div>
              <v-combobox
                v-model="court"
                :items="tournament.courts"
                label="Court"
                persistent-hint
                hint="Select a court or enter a new one"
                color="color3"
                clearable
                :error-messages="touched && !court ? 'Please select a court first' : null"
                attach dense
              >
                <template slot="no-data">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Press <kbd>enter</kbd> to add this court
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Match Number"
                v-model="matchN"
                type="number"
                @keyup.enter="addByNumber"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-expand-transition>
            <div v-if="court">
              <v-row dense>
                <v-col cols="12">
                  <div :class="{'font-weight-bold': court}">2. Select the matches</div>
                  <v-combobox
                    v-model="matches"
                    hide-selected
                    multiple
                    chips
                    label="Match Order"
                    attach
                    hint="Select matches by clicking them on the bracket"
                    persistent-hint
                    clearable dense
                  >
                    <template slot="selection" slot-scope="data">
                      <v-chip
                        color="color2 color2Text--text"
                        :key="data.item.number"
                      >
                        <v-icon
                          v-if="!data.item.status && data.index > 0 && !matches[data.index - 1].status"
                          left
                          class="clickable"
                          @click.stop="move(data.index, -1)"
                        >fas fa-caret-left</v-icon>
                        Match {{data.item.displayNumber}}
                        <v-icon
                          small
                          class="ml-2 clickable"
                          @click.stop="remove(data.index)"
                          v-if="!data.item.status"
                        >fas fa-times</v-icon>
                        <v-icon
                          v-if="!data.item.status && data.index < (matches.length - 1)"
                          right
                          class="clickable"
                          @click.stop="move(data.index, 1)"
                        >fas fa-caret-right</v-icon>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error white text" fab @click.stop="open = false" small>
          <v-icon>fas fa-times</v-icon>
        </v-btn>
        <v-btn color="color3 color3Text--text" :disabled="!saveable" fab @click.stop="saveEdit" small :loading="loading">
          <v-icon>fas fa-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-btn
      fab
      color="color3Text color3--text"
      class="not-printable"
      bottom
      right
      fixed
      style="margin-right: 120px"
      @click.stop="open = true"
    >
      <v-icon>fas fa-map-marked-alt</v-icon>
    </v-btn>

  </div>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import { mapGetters } from 'vuex'
import { firstBy } from 'thenby'

export default {
  mixins: [RouteMixin],
  props: ['bracket'],
  data () {
    return {
      open: false,
      court: null,
      matches: [],
      loading: false,
      touched: false,
      search: null,
      matchN: null
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    saveable () {
      return this.court && this.dirty
    },
    dto () {
      var dto = []
      this.matches.forEach((m, i) => {
        const n = (i + 1)
        if (m.court !== this.court || m.courtOrder !== n) {
          dto.push({
            number: m.number,
            court: this.court,
            courtOrder: n,
            bracketId: m.bracketId,
            id: m.id
          })
        }
      })
      this.bracket.allMatches.filter(f => f.court === this.court).forEach(m => {
        const x = this.matches.find(f => f.number === m.number)
        if (!x) {
          dto.push({
            number: m.number,
            court: null,
            courtOrder: null,
            bracketId: m.bracketId,
            id: m.id
          })
        }
      })
      return dto
    },
    dirty () {
      return this.dto.length
    }
  },
  methods: {
    addByNumber () {
      const match = this.bracket.matches.find(f => f.displayNumber === +this.matchN)
      console.log(match)
      if (match) {
        this.addMatch(match)
      }
      this.matchN = null
    },
    addMatch (match) {
      if (!this.court) {
        this.touched = true
        return
      }
      var x = this.matches.find(f => f.number === match.number)
      if (x) return
      this.matches.push(match)
    },
    remove (i) {
      this.matches.splice(i, 1)
    },
    move (i, n) {
      this.matches.splice((i + n), 0, ...this.matches.splice(i, 1))
    },
    saveEdit () {
      if (this.dirty) {
        this.loading = true
        this.$VBL.post.matches(this.dto)
          .then(r => { this.reset() })
          .catch(err => console.log(err.response))
          .finally(() => {
            this.loading = false
          })
      }
    },
    reset () {
      this.matches = []
      this.court = null
      this.touched = false
    },
    load () {
      if (this.court && this.bracket) {
        this.matches = this.bracket.allMatches.filter(f => f.court === this.court).sort(firstBy('courtOrder'))
        this.matches.forEach((m, i) => {
          m.courtOrder = i + 1
        })
      } else {
        this.matches = []
      }
    }
  },
  watch: {
    open: function (v) {
      this.$emit('open-change', v)
      if (!v) {
        this.reset()
      }
    },
    court: 'load'
  }
}
</script>

<style>
.floating-card {
  position: fixed;
  top: 0;
  z-index: 99;
}
</style>
